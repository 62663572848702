<template>
  <v-navigation-drawer
    left
    fixed
    overlay-color="secondary"
    overlay-opacity=".8"
    width="100%"
    height="100vh"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <v-list-item>
        <v-list-item-content>
        <v-list-item-title><base-img
        :src="require('@/assets/logo-istpro.png')"
        class="mr-2"
        contain
        max-width="150"
        width="100%"
      /></v-list-item-title>
          </v-list-item-content>
          <v-btn
          icon
          @click="Close()"
        >
          <v-icon large color="primary">mdi-close</v-icon>
        </v-btn>
        </v-list-item>
      <v-list-group
        v-for="item in items"
        :key="item.id"
        no-action
        :append-icon="item.child.length === 0 ? '' : 'mdi-chevron-down'"
      >
        <template v-slot:activator>
          <v-list-item-content @click="item.child.length === 0 ? URL(item.id, item.flag) : ''">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.child"
          :key="child.title"
          @click="URL(child.id, child.flag)"
          class="ml-n15"
        >
          <v-list-item-content>
            <v-list-item-title><v-icon>{{child.icon}}</v-icon> <span class="secondary--text">{{child.title}}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    methods: {
      Close () {
        this.$emit('close', false)
      },
      URL (id, flag) {
        if (id !== '') {
          if (flag === 'produk') {
            this.$router.push({name: 'produk-category', params: { url: id }})
          } else {
            this.$router.push({name: id}).catch(()=>{})
          }
        }
      },
    }
  }
</script>